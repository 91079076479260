// noinspection JSUnresolvedVariable

function Get_Blogs({locale: locale, page = 1, limit = 6}, models) {
    return new Promise((resolve) => {
        models
            .blogs
            .countDocuments({})
            .exec((count_error, count) => {
                if (count_error) {
                    let res;
                    return res.json(count_error);
                }
                const pageCount = Math.ceil(count / limit);
                const skip = (+page - 1) * limit;
                models
                    .blogs
                    .find()
                    .skip(skip)
                    // .limit(+limit)
                    .exec((err, _blogs) => {
                        let translatedData = []
                        _blogs.map((blog) => {
                            let translated = blog?.translations?.find((translate) => {
                                if (translate.locale === locale) {
                                    const locale = JSON.parse(JSON.stringify({
                                        ...translate,
                                        image: blog.image,
                                        created_at: blog.created_at
                                    }));
                                    translatedData?.push(locale);
                                }
                            });
                            translatedData?.push(translated);
                        });
                        const newTranslatedData = translatedData?.filter(function (element) {
                            return element !== undefined;
                        });

                        resolve({
                            data: newTranslatedData,
                            meta: {
                                current_page: page,
                                total: pageCount,
                            },
                            links: {},
                        });
                    })

            })
    });
}

function Get_Blog_By_Slug({locale: locale, url_key}, models) {
    return new Promise((resolve, reject) => {
        models
            .blogs
            .findOne({url_key})
            .then((blog) => {
                let translatedData = [];
                blog?.translations.map((item) => {
                    if (item.locale === locale) {
                        const locale = JSON.parse(JSON.stringify({
                            ...item,
                            image: blog.image,
                            created_at: blog.created_at
                        }));
                        translatedData.push(locale);
                    }
                });
                const newTranslatedData = translatedData?.filter(function (element) {
                    return element !== undefined;
                });
                resolve({data: newTranslatedData});
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Blogs,
    Get_Blog_By_Slug,
};