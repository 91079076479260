// noinspection JSUnresolvedVariable

function Get_SliderData(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .sliders
            .find()
            .then((res) => {
                const sliders = JSON.parse(JSON.stringify(res));
                const filteredSliders = sliders.filter((slider) => {
                    return slider.locale.includes(locale) &&
                        !slider.expired_at?.includes(new Date().toISOString().slice(0, 10));
                });
                const slidersSortBy = filteredSliders.sort((a, b) => a.sort_order - b.sort_order)
                resolve({sliders: slidersSortBy})
            })
            .catch((err) => reject(err));
    });
}

function Get_ChanelInfo(models) {
    return new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            .catch((err) => reject(err));
    });
}

function Get_Settings(locale, models) {
    const storeName = process.env.databaseName;
    const p1 = new Promise((resolve, reject) => {
        models
            .channel_info
            .find()
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            .catch((err) => reject(err));
    });

    const p2 = new Promise((resolve, reject) => {
        models
            .social
            .find()
            .then((res) => {
                resolve({social: JSON.parse(JSON.stringify(res))})
            })
            .catch((err) => reject(err));
    });

    return new Promise((resolve, reject) => {
        Promise
            .all([p1, p2])
            .then(async (responseArray) => {
                let [customSettingsCollection] = await Promise.all([responseArray.reduce((acc, next) => {
                    const [key] = Object.keys(next);
                    return {
                        ...acc,
                        [key]: next[key],
                    };
                }, {})]);
                customSettingsCollection.folder_name = storeName;
                resolve(customSettingsCollection);
            })
            .catch((err) => reject(err));
    });
}

function Get_Core_Config(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .core_config
            .aggregate([
                {
                    $match: {
                        code: {
                            $in: [
                                "general.info.general.phone",
                                "general.info.general.footer_email",
                                "general.info.general.footer_address",
                                "general.content.custom_scripts.custom_css",
                                "general.content.custom_scripts.custom_javascript",
                                "general.gdpr.general.content",
                                "general.gdpr.general.title",
                                "general.gdpr.general.active",
                                "catalog.products.homepage.out_of_stock_items",
                                "catalog.products.guest-checkout.allow-guest-checkout",
                                "catalog.inventory.stock_options.backorders",
                                "theme.blog.active",
                                "theme.contact_us.active",
                                "theme.subscription.active",
                                "sales.tracking.tracking.active",
                                "customer.settings.social_login.enable_linkedin",
                                "customer.settings.social_login.enable_facebook",
                                "customer.settings.social_login.enable_twitter",
                                "customer.settings.social_login.enable_github",
                                "customer.settings.social_login.enable_google",
                                "general.cash_control.version",
                                "general.content.footer.footer_toggle",
                                "general.content.footer.footer_content",
                            ],
                        },
                    }
                },
                {
                    $group: {
                        _id: "$code",
                        matchingDocuments: {
                            $push: {
                                id: "$id",
                                value: "$value",
                                channel_code: "$channel_code",
                                locale_code: "$locale_code",
                                created_at: "$created_at",
                                updated_at: "$updated_at"
                            }
                        }
                    }
                }
            ])
            .then((res) => {
                const core_conf = res
                    .map(el => {
                        let {_id, matchingDocuments} = el
                        let key = _id.replace(/\./g, "_");
                        let replaceSlash = key.replace(/\-/g, "_");
                        return {[replaceSlash]: matchingDocuments};
                    })
                    .map(el => {
                        for (let key in el) {
                            el[key] = el[key].filter(elem => elem?.locale_code !== "tr")
                            if (el[key][0]?.locale_code) {
                                return el
                            } else return {[key]: el[key][0]}
                        }
                    })
                    .reduce((acc, next) => {
                        return {
                            ...acc,
                            ...next,
                        };
                    }, {})
                resolve(core_conf);
            })
            .catch((err) => reject(err));
    });
}

function Get_ShopId(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;
        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .core_config
            .findOne({code: newQuery})
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err));
    })

}

function Get_Translations(query, models) {
    return new Promise((resolve, reject) => {
        let newQuery;

        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        models
            .translations
            .findOne({lang: newQuery})
            .then((res) => {
                resolve({[newQuery]: {translations: res?.data ? JSON.parse(JSON.stringify(res.data)) : {}}});
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Settings,
    Get_Core_Config,
    Get_Translations,
    Get_ShopId,
    Get_SliderData,
    Get_ChanelInfo,
};
