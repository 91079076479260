import thunkMiddleware from "redux-thunk";
import {applyMiddleware, createStore, EmptyObject} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./rootReducer";


export const loadState = () => {
    const ISSERVER = typeof window === "undefined";

    let serializedState: any;
    try {
        if (!ISSERVER) {
            serializedState = localStorage.getItem("state");
        }

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: EmptyObject) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (err) {
    }
};

const store = createStore(
    rootReducer,
    loadState(),
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);

store.subscribe(() => {
    saveState(store.getState());
});


export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch