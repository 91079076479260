export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const EMPTY_CART = "EMPTY_CART"
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';
export const CART_DELETE_ALL = 'CART_DELETE_ALL';
export const CART_UPDATE_LANGUAGE = 'CART_UPDATE_LANGUAGE';
export const CART_UP_SELL = 'CART_UP_SELL';
export const CART_CROSS_SELL = 'CART_CROSS_SELL';
export const SET_POPUP = 'SET_POPUP';
export const IS_READY_TO_ADD = 'IS_READY_TO_ADD';
export const RESET_IS_READY = 'RESET_IS_READY';
export const CART_ADD_QUANTITIES = 'CART_ADD_QUANTITIES';


