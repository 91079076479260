const {menuAggregate} = require("./aggregations")
const {parseClone, buildTree} = require("./helpers")

function Get_Menus(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .menus
            .aggregate(menuAggregate(locale))
            .then((res) => {
                let recursionChildren = buildTree(res, null)
                resolve(parseClone(recursionChildren));
            })
            .catch((err) => reject(err));
    });
}

function buildCategoryTree(data, locale, parentId = null) {
    const tree = [];
    data.filter(obj => obj.parent_id === parentId).forEach(obj => {
        const translations = obj.translations.find(translation => translation.locale === locale);
        const node = {
            id: obj.id,
            name: translations?.name,
            slug: translations?.slug,
            description: translations?.description,
            meta_title: translations?.meta_title,
            meta_description: translations?.meta_description,
            meta_keywords: translations?.meta_keywords,
            display_mode: translations?.display_mode,
            children: [],
        };
        const children = buildCategoryTree(data, locale, obj.id);
        if (children.length > 0) {
            node.children = children;
        }
        tree.push(node);
    });

    return tree;
}

function Get_Categories(locale, models) {
    return new Promise((resolve, reject) => {
        models
            .categories
            .find()
            .then((res) => {
                const tree = buildCategoryTree(res, locale)

                const clonedData = parseClone(tree)
                resolve(clonedData);
            })
            .catch((err) => reject(err));
    })
}

function Get_Category_By_Search_Filter(catId, models) {

    return new Promise((resolve, reject) => {
        models
            .categories
            .find({parent_id: catId})
            .then((res) => {
                resolve(parseClone(res));
            })
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Menus,
    Get_Categories,
    Get_Category_By_Search_Filter,
}