// noinspection JSUnresolvedVariable,JSUnresolvedFunction

function Save_Builder_data(body, models) {
    return new Promise(async (resolve, reject) => {
        const existingBuilder = await models
            .page_builder
            .findOne({
                page_id: +body.page_id,
                selectedLocale: body.selectedLocale
            });

        if (existingBuilder) {
            // README!!! A document with the same page_id and selectedLocale already exists, so update it.
            existingBuilder.tree = body.tree;
            existingBuilder.components = body.children;
            existingBuilder.initialLayout = body.initialLayout;
            existingBuilder.componentsBuilder = body.componentsBuilder;
            existingBuilder.save()
                .then(() => resolve("The item was updated successfully"))
                .catch(err =>{
                    console.log(err,"err")
                    reject(err)
                } );
        } else {
            //README!!! No document with the same page_id and selectedLocale exists, so create a new one.
            const newBuilder = new models
                .page_builder({
                    page_id: body.page_id,
                    selectedLocale: body.selectedLocale,
                    tree: body.tree,
                    components: body.children,
                    initialLayout: body.initialLayout,
                    componentsBuilder: body.componentsBuilder
                });
            newBuilder.save()
                .then(() => resolve("The item was saved successfully"))
                .catch(err =>{
                    console.log(err,"err 2")
                    reject(err)
                });
        }
    })

}

function Get_Data(pageId, locale, models) {
    return new Promise(async (resolve, reject) => {
        models
            .page_builder
            .aggregate([
                {
                    $match: {
                        page_id: +pageId,
                        selectedLocale: locale
                    },
                }
            ])
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            .catch((err) => reject(err))
    })
}

module.exports = {
    Get_Data,
    Save_Builder_data,
}

