// noinspection JSUnresolvedVariable

function Get_Filters(props, models) {
    const {lang, category_id} = props;
    return new Promise((resolve, reject) => {
        models
            .category_filterable_attributes
            .aggregate([
                {
                    $match: {category_id: category_id}
                },
                {
                    $lookup: {
                        from: "attributes",
                        localField: "attribute_id",
                        foreignField: "id",
                        as: "attributes",
                        pipeline: [{
                            $match: {
                                "translations": {
                                    "$all": [
                                        {"$elemMatch": {"locale": lang}}

                                    ]
                                }
                            }
                        }]
                    }
                },
                {
                    $lookup: {
                        from: "attribute_options",
                        localField: "attribute_id",
                        foreignField: "attribute_id",
                        as: "attribute_options",
                        pipeline: [{
                            $match: {
                                "translations": {
                                    "$all": [
                                        {"$elemMatch": {"locale": lang}}

                                    ]
                                }
                            }
                        }]
                    }
                },
            ])
            .then(response => resolve(response))
            .catch(err => reject(err))
    });
}

function Get_All_Filters(props, models) {
    const {lang} = props;
    return new Promise((resolve, reject) => {
        models
            .category_filterable_attributes
            .aggregate([
                {
                    $lookup: {
                        from: "attributes",
                        localField: "attribute_id",
                        foreignField: "id",
                        as: "attributes",
                        pipeline: [{
                            $match: {
                                "translations": {
                                    "$all": [
                                        {"$elemMatch": {"locale": lang}}

                                    ]
                                }
                            }
                        }]
                    }
                },
                {
                    $lookup: {
                        from: "attribute_options",
                        localField: "attribute_id",
                        foreignField: "attribute_id",
                        as: "attribute_options",
                        pipeline: [{
                            $match: {
                                "translations": {
                                    "$all": [
                                        {"$elemMatch": {"locale": lang}}

                                    ]
                                }
                            }
                        }]
                    }
                },
            ])
            .then(response => {
                const uniqueResponse = response.reduce((unique, item) => {
                    if (!unique.some(i => i.attributes[0]?.code === item.attributes[0]?.code)) {
                        unique.push(item);
                    }
                    return unique;
                }, []);

                resolve(uniqueResponse)
            })
            .catch(err => reject(err))
    });
}

module.exports = {
    Get_Filters,
    Get_All_Filters,
};
