export {default as Account} from "-!svg-react-loader!./Account.svg";
export {default as AddNewAddress} from "-!svg-react-loader!./add-new-address.svg";
export {default as ApprovedIcon} from "-!svg-react-loader!./approvved.svg";
export {default as ArrowDown} from "-!svg-react-loader!./arrow-down.svg";
export {default as ArrowRoundedDown9x6Svg} from "-!svg-react-loader!./arrow-rounded-down-9x6.svg";
export {default as ArrowRoundedDown12x7Svg} from "-!svg-react-loader!./arrow-rounded-down-12x7.svg";
export {default as ArrowRoundedLeft6x9Svg} from "-!svg-react-loader!./arrow-rounded-left-6x9.svg";
export {default as ArrowRoundedLeft8x13Svg} from "-!svg-react-loader!./arrow-rounded-left-8x13.svg";
export {default as ArrowRoundedRight6x9Svg} from "-!svg-react-loader!./arrow-rounded-right-6x9.svg";
export {default as ArrowRoundedRight8x13Svg} from "-!svg-react-loader!./arrow-rounded-right-8x13.svg";
export {default as ArrowRoundedUp13x8Svg} from "-!svg-react-loader!./arrow-rounded-up-13x8.svg";
export {default as BlogSVG} from "-!svg-react-loader!./blog.svg";
export {default as BNBIcon} from "-!svg-react-loader!./BNBIcon.svg";
export {default as BUSDIcon} from "-!svg-react-loader!./BUSDIcon.svg";
export {default as DropdownCloseSVG} from "-!svg-react-loader!./caret-down.svg";
export {default as DropdownOpenSVG} from "-!svg-react-loader!./caret-up.svg";
export {default as Cart16Svg} from "-!svg-react-loader!./cart-16.svg";
export {default as CartFill} from "-!svg-react-loader!./cartFil.svg";
export {default as CartNewtSvg} from "-!svg-react-loader!./cartNews.svg";
export {default as CategorySVG} from "-!svg-react-loader!./categorys.svg";
export {default as CheckToastSvg} from "-!svg-react-loader!./check.svg";
export {default as Check9x7Svg} from "-!svg-react-loader!./check-9x7.svg";
export {default as Check12x9Svg} from "-!svg-react-loader!./check-12x9.svg";
export {default as Check100Svg} from "-!svg-react-loader!./check-100.svg";
export {default as CheckoutFailSVG} from "-!svg-react-loader!./checkout-fail.svg";
export {default as ContactusSVG} from "-!svg-react-loader!./contact-us.svg";
export {default as ContactsSVG} from "-!svg-react-loader!./contacts.svg";
export {default as CookieSVG} from "-!svg-react-loader!./cookie-image.svg";
export {default as CreditCardStripe} from "-!svg-react-loader!./credit_card.svg";
export {default as Cross10Svg} from "-!svg-react-loader!./cross-10.svg";
export {default as Cross20Svg} from "-!svg-react-loader!./cross-20.svg";
export {default as CrosselSvg} from "-!svg-react-loader!./crossSell.svg";
export {default as CustomSelectArrow} from "-!svg-react-loader!./custom-select_arrow.svg";
export {default as CustomSelectSelectedDel} from "-!svg-react-loader!./custom-select_selected-del.svg";
export {default as CVV} from "-!svg-react-loader!./cvv.svg";
export {default as EditAddress} from "-!svg-react-loader!./Edit-address.svg";
export {default as Facebook} from "-!svg-react-loader!./facebook.svg";
export {default as FailSvg} from "-!svg-react-loader!./fail.svg";
export {default as File} from "-!svg-react-loader!./file.svg";
export {default as Github} from "-!svg-react-loader!./github.svg";
export {default as Google} from "-!svg-react-loader!./google.svg";
export {default as History} from "-!svg-react-loader!./History.svg";
export {default as HomenewSVG} from "-!svg-react-loader!./home2.svg";
export {default as ArrowBackSvg} from "-!svg-react-loader!./Icon-arrow-round-back.svg";
export {default as ImageIcon} from "-!svg-react-loader!./image_icon.svg";
export {default as Linkedin} from "-!svg-react-loader!./linkedin.svg";
export {default as Location} from "-!svg-react-loader!./Location.svg";
export {default as LogoSmallSvg} from "-!svg-react-loader!./logo-small.svg";
export {default as LogOut} from "-!svg-react-loader!./LogOut.svg";
export {default as MenuFill} from "-!svg-react-loader!./menuFil.svg";
export {default as Mobilemenu} from "-!svg-react-loader!./menumob.svg";
export {default as MenuSvg} from "-!svg-react-loader!./menuss.svg";
export {default as No} from "-!svg-react-loader!./no.svg";
export {default as PagesSVG} from "-!svg-react-loader!./page.svg";
export {default as ChangepasSvg} from "-!svg-react-loader!./paschange.svg";
export {default as PersonFill} from "-!svg-react-loader!./personFil.svg";
export {default as NewpersonSVG} from "-!svg-react-loader!./personNews.svg";
export {default as RemoveAddress} from "-!svg-react-loader!./remove-address.svg";
export {default as Search20Svg} from "-!svg-react-loader!./search-20.svg";
export {default as SearchssSvg} from "-!svg-react-loader!./searchsss.svg";
export {default as SucceSsvg} from "-!svg-react-loader!./succes.svg";
export {default as TrackingSVG} from "-!svg-react-loader!./tracking.svg";
export {default as CartTrash} from "-!svg-react-loader!./trashForCart.svg";
export {default as Twitter} from "-!svg-react-loader!./twitter.svg";
export {default as VektorMenu} from "-!svg-react-loader!./vektorMenu.svg";
export {default as Wish} from "-!svg-react-loader!./wish.svg";
export {default as WishCircle} from "-!svg-react-loader!./WishCircle.svg";
export {default as Wishlist16Svg} from "-!svg-react-loader!./wishlist-16.svg";
export {default as Yes} from "-!svg-react-loader!./yes.svg";
export {default as ZoomIn24Svg} from "-!svg-react-loader!./zoom-in-24.svg";
