const parseClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const buildTree = (array, parentId) => {
    let children = []
    for (let i = 0, length = array.length; i < length; i++) {
        if (array[i].parent_id === parentId) {
            let child = array[i]
            child.children = buildTree(array, child.id)
            children.push(child)
        }
    }
    return children
}

module.exports = {
    parseClone,
    buildTree,
}