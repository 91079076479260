import {
    MOBILE_MENU_CLOSE,
    MOBILE_MENU_OPEN,
    MOBILE_SEARCH_CLOSE,
    MOBILE_SEARCH_OPEN,
    SET_MOBILE_MENU_LIST
} from './mobileMenuActionTypes';

const initialState = {
    open: false,
    mobileMenuList: [],
    searchOpen: false
};

export default function mobileMenuReducer(state = initialState, action) {
    switch (action.type) {
        case SET_MOBILE_MENU_LIST:
            return {
                ...state,
                mobileMenuList: action.payload,
            };
        case MOBILE_MENU_OPEN:
            return {
                ...state,
                open: !state.open,
            };
        case MOBILE_MENU_CLOSE:
            return {
                ...state,
                open: false,
            };
        case MOBILE_SEARCH_OPEN:
            return {
                ...state,
                searchOpen: true,
            };
        case MOBILE_SEARCH_CLOSE:
            return {
                ...state,
                searchOpen: false,
            };
        default:
            return state;
    }
}
