
import {FOUNDEND_SMART_SEARCH} from './smartSearchActionTypes';

export function setSmartSearchUrl(payload) {
    return {
        type: FOUNDEND_SMART_SEARCH,
        payload
    };
}

