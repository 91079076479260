import {FOUNDEND_SMART_SEARCH} from './smartSearchActionTypes';

const initialState = {
   smartSearchPageUrl:""
};

export default function smartSearchReducer(state = initialState, action) {
    let newState = state;

    if (action.type === FOUNDEND_SMART_SEARCH) {
        newState = {
            smartSearchPageUrl:action.payload
        };
    }
    return newState;
}
