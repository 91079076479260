// noinspection JSUnresolvedVariable

// import {arrayConvertToObject, makeImageClone, parseClone, convertDateToSeconds} from "../services/utils";

/*this function is used*/
const parseClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

/*this function is used*/
const convertDateToSeconds = (date = null, currentDate = false) => {

    let dateObj = new Date(date)

    let currDateObj = new Date()
    return currentDate ? currDateObj.getTime() / 1000 : dateObj.getTime() / 1000;

}

/*this function is used*/
const makeImageClone = (path) => {
    return {
        path: path,
        url: `/storage/${path}`,
        large_image_url: `/cache/large/${path}`,
        medium_image_url: `/cache/medium/${path}`,
        small_image_url: `/cache/small/${path}`,
        original_image_url: `/cache/original/${path}`,
    };
}

/*this function is used*/
const allowOutOfStock = async (models) => {
    return await new Promise((resolve, reject) => {
        models
            .core_config
            .aggregate([
                {
                    $match: {
                        code: {
                            $in: [
                                "catalog.products.homepage.out_of_stock_items",
                            ],
                        },
                    }
                }
            ])
            .then(res => resolve(res[0]?.value))
            .catch(err => reject(err))
    })
}

const getInfiniteScrollValue = async (models) => {
    return await new Promise((resolve, reject) => {
        models
            .core_config
            .aggregate([
                {
                    $match: {
                        code: {
                            $in: [
                                "catalog.categories.infinite_scroll.infinite_scroll",
                            ],
                        },
                    }
                }
            ])
            .then(res => {
                    resolve(res[0]?.value)
                }
            )
            .catch(err => reject(err))
    })
}


/*this function is used*/
const filterAllAllowedProducts = (products, allowStatus) => {

    const allowedProduct = product => (product.type === "simple" && (Number(product?.product_inventories?.[0]?.qty) > 0 || +Boolean(Number(allowStatus)))) || product.type !== "simple"
    return products.map((product) => {
        if (allowedProduct(product)) {
            if (product.type === "configurable" && product?.products?.length) {
                return {
                    ...product,
                    products: product.products.filter((configVariantProduct) => allowedProduct(configVariantProduct))
                }
            }
            return product
        }
    })
        .filter(product => product)
}


// /*this function is used*/ //
function buildNeededData(res, response, total, isInfiniteScroll) {

    let setMinPrice;
    let setMaxPrice;
    if (res.length === 1) {
        setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
    } else {
        setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
        setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
    }
    let confMinPrice
    let confMaxPrice;
    res.map((item) => {
        if (item.products.length > 0) { // @ts-ignore
            confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
            confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
        }
        item.min_price = confMinPrice;
    })

    let min
    let max
    if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
        min = setMinPrice
    } else {
        min = confMinPrice
    }

    if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
        max = confMaxPrice
    } else {
        max = setMaxPrice
    }
    const allowedProducts = filterAllAllowedProducts(res, response)
    const everyThing = {
        data: allowedProducts,
        filters: [],
        links: {},
        max_price: setMaxPrice || 0,
        meta: {},
        total,
        isInfiniteScroll,
        dispatches: {
            setInitialMinPrice: min || 0,
            setInitialMaxPrice: max || 0,
        },
    };

    return everyThing
}

function defaultFilter({key, options}) {

    let dynamicSearchKey;
    switch (typeof options[key]) {
        case "boolean":
            dynamicSearchKey = "boolean_value";
            break;
        default:
            let isNum = /^\d+$/.test(options[key]);
            switch (isNum) {
                case true:
                    dynamicSearchKey = "integer_value";
                    break;
                default:
                    dynamicSearchKey = "text_value";
                    break;
            }
    }

    if (options.isSearch) {
        delete options.isSearch;
        let value;
        if (options[key].includes(",")) {
            dynamicSearchKey = "integer_value";
            value = options[key].split(",");
        } else {
            value = options[key];
        }
        return {[dynamicSearchKey]: value};
    } else {
        return {}
    }
}

function build({flatProducts, locale, resolve, ...rest}, models) {
    const promiseArray = flatProducts.map((item) => {
        return new Promise((resolve, reject) => {
            const product = parseClone(item);
            const productId = product.product_id;
            const p1 = new Promise((resolve, reject1) => {
                models
                    .product_images
                    .find({product_id: productId})
                    .then((res) => resolve({ProductImages: res}))
                    .catch((err) => reject1(err));
            });
            const p2 = new Promise((resolve, reject2) => {
                models
                    .product_flat
                    .findOne({locale, product_id: productId})
                    .then((res) => resolve({productFlat: res}))
                    .catch((err) => reject2(err));
            });
            const p3 = new Promise((resolve, reject3) => {
                models
                    .product_inventories
                    .findOne({product_id: productId})
                    .then((res) => resolve({ProductInventories: res}))
                    .catch((err) => reject3(err));
            });
            const p4 = new Promise((resolve, reject4) => {
                models
                    .products
                    .findOne({id: productId})
                    .then((res) => resolve({Products: res}))
                    .catch((err) => reject4(err));
            });

            return Promise.all([p1, p2, p3, p4])
                .then((response) => {
                    const collection = arrayConvertToObject(response);
                    const imagesData = parseClone(collection.ProductImages);
                    const flatData = parseClone(collection.productFlat || []);
                    const inventoriesData = parseClone(collection.ProductInventories || []);
                    const prods = parseClone(collection.Products || []);
                    if (imagesData[0] && imagesData[0].path) {
                        const {path} = imagesData[0];
                        const base_imag = makeImageClone(path);
                        const images = imagesData.map((e) => makeImageClone(e.path));
                        let variants = [];
                        rest && rest.products && rest.products.map((it) => {
                            let x = Object.keys(it);
                            x?.find((str) => {
                                if (str === "variants") {
                                    variants = [...it[str]];
                                } else {
                                    return false;
                                }
                            });
                        });

                        const obj = {
                            ...prods,
                            ...flatData,
                            ...inventoriesData,
                            base_imag,
                            variants,
                            images,
                        };
                        resolve(obj);
                    }
                    resolve([]);
                })
                .catch((err) => reject(err));
        });
    });

    return Promise.all(promiseArray).then((response) => {
        let everyThing;
        if (rest.prices && rest.prices.length > 0) {
            let setInitialMinPrice;
            let setInitialMaxPrice;
            setInitialMinPrice = Math.min.apply(Math, response.map(function (o) {
                return o.price;
            }));
            setInitialMaxPrice = Math.max.apply(Math, response.map(function (o) {
                return o.price;
            }));

            everyThing = {
                data: response,
                filters: [],
                links: {},
                max_price: rest.prices[1] || 1,
                meta: {},
                total: rest.total,
                dispatches: {
                    setInitialMinPrice: setInitialMinPrice || 1,
                    setInitialMaxPrice: setInitialMaxPrice || 1,
                },
            };
        } else {

            everyThing = {
                [rest.type]: response,
            };
        }
        resolve(everyThing);
    });
}

async function buildProductsListCollection({flat, savings, price, ...rest}, models) {

    if (flat) {
        const {flatProducts} = rest;
        const [from, to] = price.split(",");
        const flatProductsFiltered = flatProducts?.filter((flatProduct) => {
            if (flatProduct.price >= from && flatProduct.price <= parseFloat(to) + 5) {
                return flatProduct;
            }
        });
        await build({...rest, flatProducts: flatProductsFiltered}, models);
    } else {
        await build(rest, models);
    }
}

function arrayConvertToObject(response) {
    return response.reduce((prev, next) => {
        const keys = Object.keys(next);
        if (keys.length > 1) {
            const array = keys.reduce((a, n) => {
                return {
                    ...a, [n]: next[n],
                };
            }, {});
            return {...prev, ...array};
        } else {
            return {...prev, [keys]: next[keys]};
        }
    }, {});
}

const START_DATE = "1970-01-01";

function findMinimalOrMaximalPrice(obj, order) {
    if (obj) {
        const {price, min_price, max_price, special_price} = obj
        let newDate = new Date();
        let pricesArr
        let date_from = obj.special_price_from
        let date_to = obj.special_price_to
        const date_now = newDate;
        if (special_price && date_now >= date_from && date_now <= date_to) {
            pricesArr = [price, min_price, max_price, special_price]
        } else if (special_price && date_from === START_DATE && date_to === START_DATE) {
            pricesArr = [price, min_price, max_price, special_price]
        } else {
            pricesArr = [price, min_price, max_price]
        }

        if (price) {
            if (order === 1) {
                return pricesArr?.filter(el => el !== null).sort((a, b) => b - a)[0]
            } else {
                return pricesArr?.filter(el => el !== null).sort((a, b) => a - b)[0]
            }
        }
    }

}

function Get_New_And_Featured_Products(opts, models) {
    const {locale, limit} = opts
    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                const newProducts = new Promise((resolve, reject1) => {
                    models
                        .products
                        .aggregate([
                            {
                                $lookup: {
                                    from: "product_flat",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_flat",
                                    pipeline: [{
                                        $match: {
                                            $and: [{new: 1}, {locale: locale}, {parent_id: null}]
                                        }
                                    }]
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_inventories",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_inventories"
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_images",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_images",
                                    pipeline: [{
                                        $project: {
                                            _id: 1,
                                            id: 1,
                                            type: 1,
                                            path: 1,
                                            product_id: 1,

                                        },
                                    }]
                                }
                            },
                            {
                                $lookup: {
                                    from: "attributes",
                                    as: "block_title",
                                    pipeline: [{
                                        $match: {code: "new"}
                                    }]
                                }
                            },
                            {
                                $match: {"product_flat.new": 1}
                            }
                        ])
                        .limit(limit)
                        .then((res) => {
                            res = JSON.parse(JSON.stringify(res))
                            let modifiedRes = res.map(el => {
                                return {
                                    ...el,
                                    min_price: el.product_flat[0].min_price,
                                    block_title: el.block_title[0].is_visible_on_front !== "0"? el.block_title[0].translations?.filter(elem => elem.locale === locale)[0]?.name : ""
                                }
                            })
                            const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                            resolve(allowedProducts)
                        })
                        .catch((err) => reject1(err))
                })

                const featuredProducts = new Promise((resolve, reject2) => {
                    models
                        .products
                        .aggregate([
                            {
                                $lookup: {
                                    from: "product_flat",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_flat", pipeline: [{
                                        $match: {
                                            $and: [{featured: 1}, {locale: locale}, {parent_id: null}]
                                        }
                                    }]
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_inventories",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_inventories"
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_images",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_images",
                                    pipeline: [{
                                        $project: {
                                            _id: 1,
                                            id: 1,
                                            type: 1,
                                            path: 1,
                                            product_id: 1,

                                        },
                                    }]
                                }
                            },
                            {
                                $lookup: {
                                    from: "attributes",
                                    as: "block_title",
                                    pipeline: [{
                                        $match: {code: "featured"}
                                    }]
                                }
                            },
                            {
                                $match: {"product_flat.featured": 1}
                            }
                        ])
                        .limit(limit)
                        .then((res) => {
                            res = JSON.parse(JSON.stringify(res))
                            let modifiedRes = res
                                .map(el => {
                                    return {
                                        ...el,
                                        min_price: el.product_flat[0].min_price,
                                        block_title: el.block_title[0].is_visible_on_front !== "0"? el.block_title[0].translations?.filter(elem => elem.locale === locale)[0]?.name : ""
                                    }
                                })

                            const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                            resolve(allowedProducts)
                        })
                        .catch((err) => reject2(err))
                })
                    .catch(err => reject(err));

                return Promise
                    .all([featuredProducts, newProducts])
                    .then((response) => {

                        let allHomeProducts = {
                            new: response[1],
                            featured: response[0]
                        }
                        resolve(allHomeProducts);
                    });
            });
        })
}

function Get_Product_list(options, models) {
    const {locale: defaultLocale, limit: limitProduct, page} = options;
    const limit = limitProduct || 20;
    let locale;
    if (typeof defaultLocale != "string") {
        locale = defaultLocale[0];
    } else {
        locale = defaultLocale;
    }
    let searchKeys = {};
    for (let key in options) {
        if (key !== "limit" && key !== "category_id" && key !== "currency" && key !== "locale" && key !== "page") {
            switch (key) {
                case "savings":
                    break;
                case "price":
                    // const [from, to] = options["price"].split(",");
                    // searchKeys = {
                    //   ...searchKeys,
                    //   prices: { $gte: from + ".0000", $lte: to + ".0000" },
                    // };
                    break;
                default:
                    searchKeys = {
                        ...searchKeys,
                        ...defaultFilter({key, options, searchKeys}),
                    };
            }
        }
    }

    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                models
                    .products_categories
                    .find({slug: options.slug})
                    .then((res) => {
                        const productIdsByCategory = res.map((e) => e.product_id);
                        const paramsArray = Object.keys(JSON.parse(JSON.stringify(searchKeys)));
                        const buildQueryParams = paramsArray.reduce((acc, next) => {
                            if (typeof searchKeys[next] == "object" && searchKeys[next]?.length > 0) {
                                return {...acc, [next]: {$in: searchKeys[next]}};
                            } else {
                                return {...acc, [next]: searchKeys[next]};
                            }
                        }, {});
                        if (Object.keys(buildQueryParams)[0] !== "text_value") {
                            let productIds;
                            models
                                .product_attribute_values
                                .find({...buildQueryParams})
                                .then((res) => {
                                    productIds = productIdsByCategory?.filter((id) => {
                                        const find = res?.find((e) => e.product_id == id);
                                        if (find) {
                                            return id;
                                        }
                                    });

                                    if (productIds.length === 0) {
                                        resolve([]);
                                    }
                                    const productsPromise = new Promise((resolve, reject) => {
                                        let arrayData = [];
                                        models
                                            .products
                                            .find({
                                                id: {$in: productIds},
                                                type: "simple"
                                            })
                                            .then((products) => {
                                                arrayData = parseClone(products);
                                                models
                                                    .products
                                                    .find({
                                                        id: {$in: productIds}, type: "configurable",
                                                    })
                                                    .then((items) => {
                                                        if (items.length === 0) {
                                                            resolve({arrayData});
                                                        }
                                                        items.map((item) => {
                                                            const itemProduct = parseClone(item);
                                                            models
                                                                .product_flat
                                                                .find({parent_id: itemProduct.id})
                                                                .then((res) => {
                                                                    return (itemProduct["variants"] = res);
                                                                })
                                                                .then(() => {
                                                                    let productsData;
                                                                    productsData = [...arrayData];
                                                                    resolve(productsData);
                                                                });
                                                        });
                                                    });
                                            })
                                            .catch(err => reject(err));
                                    });

                                    const minMaxPricePromise = new Promise((resolve, reject) => {
                                        /**
                                         *  @info: Min max price Only category ID , without all filtered attributes, need to overwrite
                                         *
                                         * */
                                        let object;
                                        let date_now = null;
                                        if (productIds.length > 0) {
                                            object = {
                                                locale: locale, product_id: {$in: productIds},
                                            };
                                        } else {
                                            object = {
                                                locale: locale,
                                            };
                                        }

                                        if (options["price"]) {
                                            const [from, to] = options["price"].split(",");
                                            object = {
                                                ...object, price: {
                                                    $gte: from + ".0000", $lte: parseFloat(to) + 10 + ".0000",
                                                },
                                            };
                                        }
                                        if (options["savings"]) {
                                            let d = new Date(), month = "" + (d.getMonth() + 1), day = "" + d.getDate(),
                                                year = d.getFullYear();
                                            if (month.length < 2) month = "0" + month;
                                            if (day.length < 2) day = "0" + day;
                                            date_now = new Date(`${year}-${month}-${day}`).getTime();
                                            date_now = "" + date_now;
                                            date_now = parseInt(date_now.slice(0, -3));

                                            object = {
                                                ...object, special_price: {$ne: null},
                                            };

                                            models
                                                .product_flat
                                                .where("special_price_from")
                                                .lte(date_now)
                                                .where("special_price_to")
                                                .gte(date_now)
                                                .countDocuments({...object})
                                                .exec((count_error, count) => {
                                                    const pageCount = Math.ceil(count / limit);
                                                    // const skip = (+page - 1) * limit;
                                                    models
                                                        .product_flat
                                                        .find({
                                                            ...object,
                                                        })
                                                        // .skip(skip)
                                                        // .limit(+limit)
                                                        .where("special_price_from")
                                                        .lte(date_now)
                                                        .where("special_price_to")
                                                        .gte(date_now)
                                                        .then((flatProducts) => {
                                                            const prices = flatProducts
                                                                .map((item) => parseInt(item.price))
                                                                ?.filter((e) => e);

                                                            resolve({
                                                                total: pageCount,
                                                                flatProducts,
                                                                page: page || 1,
                                                                prices: [0, prices[prices.length - 1] || 1000],
                                                                price: options["price"],
                                                            });
                                                        })
                                                        .catch(err => reject(err));
                                                });
                                        } else {
                                            models
                                                .product_flat
                                                .countDocuments({...object})
                                                .exec((count_error, count) => {
                                                    const pageCount = Math.ceil(count / limit);
                                                    // const skip = (+page - 1) * limit;
                                                    models.product_flat.find({...object})
                                                        // .skip(skip)
                                                        // .limit(+limit)
                                                        .then((flatProducts) => {
                                                            const prices = flatProducts
                                                                .map((item) => parseInt(item.price))
                                                                ?.filter((e) => e);

                                                            resolve({
                                                                total: pageCount,
                                                                flatProducts,
                                                                page: page || 1,
                                                                prices: [0, prices[prices.length - 1] || 1000],
                                                                price: options["price"],
                                                            });
                                                        });
                                                });
                                        }
                                    });

                                    return Promise
                                        .all([productsPromise, minMaxPricePromise])
                                        .then(async (response) => {
                                            const productsAndMinMaxPrice = arrayConvertToObject(parseClone(response));

                                            if (options["savings"] || options["price"]) {
                                                await buildProductsListCollection({
                                                    locale,
                                                    resolve,
                                                    flat: true,
                                                    price: productsAndMinMaxPrice.price,
                                                    savings: options["savings"], ...productsAndMinMaxPrice,
                                                }, models);
                                            } else {
                                                await buildProductsListCollection({
                                                        page,
                                                        locale,
                                                        resolve,
                                                        flat: false,
                                                        ...productsAndMinMaxPrice,
                                                    },
                                                    models);
                                            }
                                        });
                                });
                        } else {
                            models
                                .product_flat
                                .aggregate([
                                    {
                                        "$match": {
                                            name: {$regex: Object.values(buildQueryParams)[0], $options: "i"}, locale,
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "product_id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_inventories",
                                            localField: "product_id",
                                            foreignField: "product_id",
                                            as: "product_inventories"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "product_id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "products",
                                            localField: "product_id",
                                            foreignField: "id",
                                            as: "products"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        $and: [{code: "sku"}]
                                                    }
                                                },
                                            ],
                                            as: "sku_option"
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "products_categories",
                                            localField: "product_id",
                                            foreignField: "id",
                                            as: "categories",
                                        }
                                    },

                                ])
                                .then((res) => {
                                    res = parseClone(res)


                                    const modifiedRes = res.map(item => {

                                        let type = item.products[0].type
                                        let base_image;

                                        let productFlat = item.product_flat[0]
                                        let currentDateInSeconds = convertDateToSeconds(null, true)

                                        // checking special price expiring time in node.js before sending data in front
                                        if (productFlat.special_price_from && productFlat.special_price_to) {
                                            if (currentDateInSeconds > productFlat.special_price_to || currentDateInSeconds < productFlat.special_price_from) {
                                                item.special_price = null
                                                productFlat.special_price = null
                                                if (item.product) {
                                                    item.product.special_price = null
                                                }

                                            }
                                        }
                                        if (item.product_images.length > 0) {
                                            base_image = makeImageClone(item.product_images[0].path)
                                            item.product_images[0] = base_image
                                        }

                                        return {...item, type}
                                    })

                                    const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)

                                    resolve({data: allowedProducts})

                                })
                        }
                    })
                    .catch(err => reject(err));
            });
        })


}

const Get_Product_For_Product_Inner_Page = (productSlug, options, models) => {
    const {locale} = options;

    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                models
                    .products
                    .aggregate([
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            $and: [
                                                {url_key: productSlug},
                                                {locale: locale}
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_attribute_values",
                                localField: "id",
                                foreignField: "product_id",
                                as: "details",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            localField: "attribute_id",
                                            foreignField: "id",
                                            as: "attribute",
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "attribute_options",
                                            localField: " ",
                                            foreignField: " ",
                                            as: "attribute_options",
                                        }
                                    },
                                ]
                            },

                        },
                        {
                            $lookup: {
                                from: "attributes",
                                pipeline: [
                                    {
                                        $match: {code: "sku"}
                                    },
                                ],
                                as: "sku_option"
                            },
                        },
                        {
                            $lookup: {
                                from: "attributes",
                                pipeline: [
                                    {
                                        $match: {code: "short_description"}
                                    },
                                ],
                                as: "short_description_option"
                            },
                        },
                        {
                            $lookup: {
                                from: "attributes",
                                pipeline: [
                                    {
                                        $match: {code: "description"}
                                    },
                                ],
                                as: "description_option"
                            },
                        },
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "attributes",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            localField: "attribute_id",
                                            foreignField: "id",
                                            as: "attributes_values",
                                            pipeline: [
                                                {
                                                    $lookup: {
                                                        from: "attribute_options",
                                                        localField: "id",
                                                        foreignField: "attribute_id",
                                                        as: "attribute_options",
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "yoast_products",
                                localField: "id",
                                foreignField: "product_id",
                                as: "yoast_products",
                                pipeline: [
                                    {
                                        $match: {
                                            $expr: {
                                                $eq: ["$locale", locale]
                                            }
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_attribute_values",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_attribute_values",

                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_inventories",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_inventories"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    },
                                                }
                                            ]
                                        },
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_bundle_options",
                                localField: "id",
                                foreignField: "product_id",
                                as: "bundle_products",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_bundle_option_products",
                                            localField: "id",
                                            foreignField: "product_bundle_option_id",
                                            as: "bundle_product_options",
                                            pipeline: [
                                                {
                                                    $lookup: {
                                                        from: "product_flat",
                                                        localField: "product_id",
                                                        foreignField: "product_id",
                                                        as: "product_flat",
                                                        pipeline: [
                                                            {
                                                                $lookup: {
                                                                    from: "product_inventories",
                                                                    localField: "id",
                                                                    foreignField: "product_id",
                                                                    as: "product_inventories"
                                                                }
                                                            },
                                                            {
                                                                $lookup: {
                                                                    from: "product_images",
                                                                    localField: "product_id",
                                                                    foreignField: "product_id",
                                                                    as: "product_images",
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    $lookup: {
                                                        from: "product_images",
                                                        localField: "product_id",
                                                        foreignField: "product_id",
                                                        as: "product_images",
                                                    }
                                                },
                                            ]
                                        },
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "products_categories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "categories",
                            }
                        },
                        {$match: {"product_flat.url_key": productSlug}}])
                    .then((res) => {
                        if (!res || res.length === 0) {

                            resolve({
                                notFound: true
                            })
                        } else {
                            let newMinPrice = res[0]?.products.map((item) => item.product_flat[0].price)
                                .sort((a, b) => a - b)[0]
                            if (!newMinPrice) {
                                newMinPrice = null
                            }
                            res = JSON.parse(JSON.stringify(res))


                            if (res[0]?.type === 'bundle') {
                                const modifiedRes = res.map((item) => {
                                    let base_image;
                                    item.bundle_products.map((itemPr) => {
                                        itemPr.bundle_product_options.map((el) => {

                                            if (el.product_images.length > 0) {
                                                el.product_images.map((y, ind) => {
                                                    base_image = makeImageClone(y.path)
                                                    el.product_images[ind] = base_image
                                                })
                                            }
                                            if (el.product_flat[0].product_images.length > 0) {
                                                el.product_flat[0].product_images.map((y, ind) => {
                                                    base_image = makeImageClone(y.path)

                                                    el.product_flat[0].product_images[ind] = base_image
                                                })
                                            }
                                            return el
                                        })
                                        return itemPr
                                    })
                                    if (item.product_images.length > 0) {
                                        item.product_images.map((x, ind) => {
                                            base_image = makeImageClone(x.path)

                                            item.product_images[ind] = base_image
                                        })

                                    }
                                    return item
                                })
                                resolve(modifiedRes)
                            } else {
                                const modifiedRes = res.map(item => {
                                    let base_image;
                                    item.products.map((el) => {
                                        if (el.product_images.length > 0) {
                                            el.product_images.map((y, ind) => {
                                                base_image = makeImageClone(y.path)
                                                el.product_images[ind] = base_image
                                            })
                                        }
                                        return el
                                    })
                                    if (item.product_images.length > 0) {
                                        item.product_images.map((x, ind) => {
                                            base_image = makeImageClone(x.path)
                                            item.product_images[ind] = base_image
                                        })
                                    }
                                    item.min_price = newMinPrice;
                                    return item
                                })

                                const allowProduct = filterAllAllowedProducts(modifiedRes, allowResponse)
                                if (allowProduct.length > 0) {
                                    resolve(allowProduct)
                                } else {
                                    resolve({
                                        notFound: true
                                    })
                                }
                            }
                        }
                    })
                    .catch((err) => reject(err))
            })
        })
}

function Get_Related_Products(options, models) {
    const {locale, limit, category_id, product_id} = options;


    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                models
                    .products_relations
                    .find({
                        parent_id: {$in: [product_id]},
                    })
                    .then((res) => {
                        if (res.length > 0) {
                            const productIds = res.map((e) => e.child_id);
                            models
                                .products
                                .aggregate([
                                    {
                                        $match: {
                                            $and: [{
                                                id: {$in: productIds}
                                            }, {
                                                parent_id: null
                                            }]
                                        }
                                    },
                                    {$limit: limit},
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [{
                                                $match: {
                                                    locale: locale
                                                }
                                            }]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_inventories",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_inventories"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $match: {
                                            "product_flat.0": {
                                                "$exists": true
                                            }
                                        }

                                    }
                                ])
                                .then((res) => {

                                    res = parseClone(res)
                                    const modifiedRes = res.map(item => {
                                        let base_image;
                                        if (item.product_images.length > 0) {
                                            item.product_images.map((x, ind) => {
                                                base_image = makeImageClone(x.path)
                                                item.product_images[ind] = base_image
                                            })

                                        }
                                        return item
                                    })

                                    const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                                    resolve(allowedProducts)

                                })
                        } else {
                            models
                                .products_categories
                                .find({
                                    category_id: {$in: category_id},
                                })
                                .then((res) => {
                                    const productIds = res.map((e) => e.product_id);
                                    models
                                        .products
                                        .aggregate([{
                                            $match: {
                                                $and: [{
                                                    id: {$in: productIds}
                                                }, {
                                                    parent_id: null
                                                }]
                                            }
                                        },
                                            {$limit: limit},
                                            {
                                                $lookup: {
                                                    from: "product_flat",
                                                    localField: "id",
                                                    foreignField: "product_id",
                                                    as: "product_flat",
                                                    pipeline: [{
                                                        $match: {
                                                            locale: locale
                                                        }
                                                    }]
                                                }
                                            },
                                            {
                                                $lookup: {
                                                    from: "product_inventories",
                                                    localField: "id",
                                                    foreignField: "product_id",
                                                    as: "product_inventories"
                                                }
                                            },
                                            {
                                                $lookup: {
                                                    from: "product_images",
                                                    localField: "id",
                                                    foreignField: "product_id",
                                                    as: "product_images",
                                                }
                                            },
                                            {
                                                $match: {
                                                    "product_flat.0": {
                                                        "$exists": true
                                                    }
                                                }
                                            }
                                        ])
                                        .then((res) => {
                                            res = parseClone(res)
                                            const modifiedRes = res.map(item => {
                                                let base_image;
                                                if (item.product_images.length > 0) {
                                                    item.product_images.map((x, ind) => {
                                                        base_image = makeImageClone(x.path)
                                                        item.product_images[ind] = base_image
                                                    })
                                                }
                                                return item
                                            })

                                            const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                                            resolve(allowedProducts)

                                        })
                                });
                        }
                    })
                    .catch((err) => reject(err));
            });
        })

}

function Get_Up_Sell_Products(options, models) {
    const {locale, limit, product_id} = options;
    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                models
                    .product_up_sells
                    .find({
                        parent_id: {$in: product_id.split(",")},
                    })
                    .then((res) => {
                        if (res.length > 0) {
                            const productIds = res.map((e) => e.child_id);
                            models
                                .products
                                .aggregate([
                                    {
                                        $match: {
                                            $and: [
                                                {
                                                    id: {$in: productIds}
                                                },
                                                {
                                                    parent_id: null
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            pipeline: [
                                                {
                                                    $match: {code: "sku"}
                                                },
                                            ],
                                            as: "sku_option"
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            pipeline: [
                                                {
                                                    $match: {code: "short_description"}
                                                },
                                            ],
                                            as: "short_description_option"
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "attributes",
                                            pipeline: [
                                                {
                                                    $match: {code: "description"}
                                                },
                                            ],
                                            as: "description_option"
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "product_super_attributes",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "variants",

                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_inventories",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_inventories"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "products",
                                            localField: "id",
                                            foreignField: "parent_id",
                                            as: "products",
                                            let: {id: "$id"},
                                            pipeline: [
                                                {
                                                    $lookup: {
                                                        from: "product_flat",
                                                        localField: "id",
                                                        foreignField: "product_id",
                                                        as: "product_flat",
                                                        pipeline: [
                                                            {
                                                                $match: {
                                                                    locale: locale
                                                                }
                                                            }
                                                        ]
                                                    },
                                                },
                                                {
                                                    $lookup: {
                                                        from: "product_images",
                                                        localField: "id",
                                                        foreignField: "product_id",
                                                        as: "product_images",
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ])
                                .limit(Number(limit))
                                .then((res) => {
                                    res = parseClone(res)
                                    const modifiedRes = res.map(item => {
                                        let base_image;
                                        if (item.product_images.length > 0) {
                                            item.product_images.map((x, ind) => {
                                                base_image = makeImageClone(x.path)
                                                item.product_images[ind] = base_image
                                            })

                                        }
                                        return item
                                    })

                                    const allowedProducts = filterAllAllowedProducts(modifiedRes, allowResponse)
                                    resolve(allowedProducts)

                                })
                        } else {
                            resolve(res);
                        }
                    })
                    .catch(err => reject(err));
            });
        })
}

function Get_Cross_Sell_Products(options, models) {
    const {locale, limit, product_id} = options;

    return allowOutOfStock(models)
        .then(allowResponse => {
            return new Promise((resolve, reject) => {
                models
                    .product_cross_sells
                    .find({
                        parent_id: {$in: product_id.split(",")},
                    })
                    .then((res) => {
                        if (res.length > 0) {
                            const productIds = res.map((e) => e.child_id);
                            models
                                .products
                                .aggregate([
                                    {
                                        $match: {
                                            $and: [
                                                {
                                                    id: {$in: productIds}
                                                },
                                                {
                                                    parent_id: null
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_super_attributes",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "variants",

                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_inventories",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_inventories"
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "products",
                                            localField: "id",
                                            foreignField: "parent_id",
                                            as: "products",
                                            let: {id: "$id"},
                                            pipeline: [
                                                {
                                                    $lookup: {
                                                        from: "product_flat",
                                                        localField: "id",
                                                        foreignField: "product_id",
                                                        as: "product_flat",
                                                        pipeline: [
                                                            {
                                                                $match: {
                                                                    locale: locale
                                                                }
                                                            }
                                                        ]
                                                    },
                                                },
                                                {
                                                    $lookup: {
                                                        from: "product_images",
                                                        localField: "id",
                                                        foreignField: "product_id",
                                                        as: "product_images",
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ])
                                .limit(Number(limit))
                                .then((res) => {
                                    res = parseClone(res)
                                    const modifiedRes = res.map(item => {
                                        let base_image;
                                        if (item.product_images.length > 0) {
                                            item.product_images.map((x, ind) => {
                                                base_image = makeImageClone(x.path)
                                                item.product_images[ind] = base_image
                                            })

                                        }
                                        return item
                                    })

                                    const randomProducts = [];
                                    const newProducts = [];
                                    while (randomProducts.length < modifiedRes.length) {
                                        let r = Math.floor(Math.random() * modifiedRes.length);
                                        if (randomProducts.indexOf(r) === -1) randomProducts.push(r);
                                    }
                                    for (let i = 0; i < modifiedRes.length; i++) {
                                        newProducts.push(modifiedRes[randomProducts[i]])
                                    }
                                    const allowedProducts = filterAllAllowedProducts(newProducts, allowResponse, 100)
                                    resolve(allowedProducts)
                                })
                        } else {
                            resolve(res);
                        }
                    })
                    .catch(err => reject(err));
            });
        })
}

const Get_Category_Products = (slug, locale, limit, models, page = 1, sortBy) => {
console.log(models, "models")
    return allowOutOfStock(models)
        .then(response => {
            // return new Promise((resolve,reject) =>{
            return getInfiniteScrollValue(models).then(valueRes => {

                let isInfiniteScroll = false
                if (valueRes) {
                    isInfiniteScroll = Boolean(Number(valueRes))
                }
                // console.log(isInfiniteScroll,"AAAAAAAAA")

                return new Promise((resolve, reject) => {
                    models
                        .products_categories
                        .find({slug})
                        .then((res) => {
                            console.log(res)
                            if (!res || res.length === 0) {
                                resolve({NotFound: true})
                            } else {
                                const productIdsByCategory = res.map((e) => e.product_id);
                                models
                                    .products
                                    .aggregate([
                                        {
                                            $match: {
                                                $and: [
                                                    {
                                                        id: {$in: productIdsByCategory}
                                                    },
                                                    {
                                                        parent_id: null
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "product_super_attributes",
                                                localField: "id",
                                                foreignField: "product_id",
                                                as: "variants",
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "product_flat",
                                                localField: "id",
                                                foreignField: "product_id",
                                                as: "product_flat",
                                                pipeline: [{$match: {locale}}]
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "product_inventories",
                                                localField: "id",
                                                foreignField: "product_id",
                                                as: "product_inventories"
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "product_images",
                                                localField: "id",
                                                foreignField: "product_id",
                                                as: "product_images",
                                                pipeline: [{
                                                    $project: {
                                                        _id: 1,
                                                        id: 1,
                                                        type: 1,
                                                        path: 1,
                                                        product_id: 1,
                                                        // Include other fields you want to return
                                                    },
                                                }]
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "products",
                                                localField: "id",
                                                foreignField: "parent_id",
                                                as: "products",
                                                let: {id: "$id"},
                                                pipeline: [
                                                    {
                                                        $lookup: {
                                                            from: "product_flat",
                                                            localField: "id",
                                                            foreignField: "product_id",
                                                            as: "product_flat",
                                                            pipeline: [{$match: {locale}}]
                                                        }
                                                    },
                                                    {
                                                        $lookup: {
                                                            from: "product_images",
                                                            localField: "id",
                                                            foreignField: "product_id",
                                                            as: "product_images",
                                                            pipeline: [{
                                                                $project: {
                                                                    _id: 1,
                                                                    id: 1,
                                                                    type: 1,
                                                                    path: 1,
                                                                    product_id: 1,
                                                                    // Include other fields you want to return
                                                                },
                                                            }]
                                                        }
                                                    },
                                                    {
                                                        $lookup: {
                                                            from: "product_inventories",
                                                            localField: "id",
                                                            foreignField: "product_id",
                                                            as: "product_inventories",
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            $lookup: {
                                                from: "products_categories",
                                                localField: "id",
                                                foreignField: "product_id",
                                                as: "categories",
                                            }
                                        },
                                        {
                                            $addFields: {
                                                "product_flat.min_price_int": {
                                                    $convert: {
                                                        input: {
                                                            $cond: [
                                                                {$isArray: "$product_flat.min_price"},
                                                                {$arrayElemAt: ["$product_flat.min_price", 0]},
                                                                "$product_flat.min_price"
                                                            ]
                                                        },
                                                        to: "decimal",
                                                        onError: 0
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            $sort: typeof sortBy !== "undefined" ? {
                                                "product_flat.min_price_int": sortBy === true ? 1 : -1
                                            } : {
                                                "_id": 1
                                            }
                                        },
                                        {
                                            $facet: {
                                                paginatedResults: [
                                                    {$skip: page === 1 || isInfiniteScroll ? 0 : page * limit - limit},
                                                    {$limit: isInfiniteScroll ? limit * page : limit}
                                                ],
                                                totalCount: [
                                                    {
                                                        $count: 'total'
                                                    }
                                                ]
                                            }
                                        }

                                    ])

                                    .then((res) => {
                                        // console.log(isInfiniteScroll,"isInfiniteScrollisInfiniteScrollisInfiniteScroll")
                                        // console.log(buildNeededData(res[0].paginatedResults,response,res?.[0]?.totalCount?.[0]?.total),"resssssssss")
                                        resolve(buildNeededData(
                                            res[0].paginatedResults,
                                            response,
                                            res?.[0]?.totalCount?.[0]?.total,
                                            isInfiniteScroll)
                                        )
                                    })
                            }
                        })
                        .catch(err => reject(err))
                });
            })
            // }
            // )

        })
}


const Get_All_Products = (locale, models) => {
    return allowOutOfStock(models)
        .then(response => {
            return new Promise((resolve, reject) => {
                models
                    .products
                    .aggregate([
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "variants",
                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            locale: locale
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "products_categories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "categories",
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                let: {id: "$id"},
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    }
                                ]
                            }

                        }
                    ])
                    .then((res) => {

                        let setMinPrice;
                        let setMaxPrice;
                        if (res.length === 1) {
                            setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                        } else {
                            setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                            setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
                        }
                        let confMinPrice
                        let confMaxPrice;
                        res.map((item) => {
                            if (item.products.length > 0) { // @ts-ignore
                                confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
                                confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
                            }
                            item.min_price = confMinPrice;
                        })

                        let min
                        let max
                        if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
                            min = setMinPrice
                        } else {
                            min = confMinPrice
                        }

                        if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
                            max = confMaxPrice
                        } else {
                            max = setMaxPrice
                        }
                        const allowedProducts = filterAllAllowedProducts(res, response)

                        const everyThing = {
                            data: allowedProducts,
                            filters: [],
                            links: {},
                            max_price: setMaxPrice || 0,
                            meta: {},
                            total: 100,
                            dispatches: {
                                setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
                            },
                        };

                        resolve(everyThing)
                    })
                    .catch(err => reject(err))
            })
        })
}

module.exports = {
    Get_New_And_Featured_Products,
    Get_Product_For_Product_Inner_Page,
    Get_Category_Products,
    Get_All_Products,
    Get_Product_list,
    Get_Related_Products,
    Get_Cross_Sell_Products,
    Get_Up_Sell_Products,
}
