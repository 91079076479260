/*** getting menu with pages together ***/
const menuAggregate = (locale) => {
    return [
        {
            $unwind: {
                path: "$translations",
                preserveNullAndEmptyArrays: true,
            }
        },
        {
            $match: {
                "translations.locale": locale
            }
        },
        {
            $lookup: {
                from: "pages",
                let: {pageId: "$page_id"},
                pipeline: [
                    {
                        $match: {
                            $expr: {
                                $or: [
                                    {$eq: ["$id", "$$pageId"]},
                                    {$eq: ["$$pageId", null]}
                                ]
                            }
                        }
                    },
                    {
                        $unwind: {
                            path: "$translations",
                            preserveNullAndEmptyArrays: true
                        }
                    },
                    {
                        $match: {
                            "translations.locale": locale
                        }
                    }
                ],
                as: "pages"
            }
        },
        {
            $unwind: {
                path: "$pages",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $unwind: {
                path: "$pages.translations",
                preserveNullAndEmptyArrays: true
            }
        },
        {
            $match: {
                "pages.translations.locale": locale
            }
        },
        {
            $group: {
                _id: "$_id",
                id: {"$first": "$id"},
                position: {"$first": "$position"},
                layout: {"$first": "$layout"},
                created_at: {"$first": "$created_at"},
                updated_at: {"$first": "$updated_at"},
                parent_id: {"$first": "$parent_id"},
                page_id: {"$first": "$page_id"},
                _lft: {"$first": "$_lft"},
                _rgt: {"$first": "$_rgt"},
                type: {"$first": "$type"},
                new_tab: {"$first": "$new_tab"},
                m_id: {"$first": "$translations.id"},
                locale: {"$first": "$translations.locale"},
                name: {"$first": "$translations.name"},
                url_key: {"$first": "$translations.url_key"},
                status: {"$first": "$translations.status"},
                children: {"$first": "$translations.children"},
                cms_menu_id: {"$first": "$translations.cms_menu_id"},
                content: {"$first": "$pages.content"},
                meta_description: {"$first": "$pages.translations.meta_description"},
                meta_title: {"$first": "$pages.translations.meta_title"},
                meta_keywords: {"$first": "$pages.translations.meta_keywords"},
                page_title: {"$first": "$pages.translations.page_title"},
                slug: {"$first": "$pages.translations.url_key"},
                p_id: {"$first": "$pages.translations.id"},
                html_content: {"$first": "$pages.translations.html_content"},
                cms_page_id: {"$first": "$pages.translations.cms_page_id"},
                p_layout: {"$first": "$pages.layout"},
            }
        },
        {
            $project: {
                _id: 0
            }
        },
        {
            $match: {
                $or: [
                    {status: 1},
                    {status: "1"},
                ]
            }
        },
        {
            $sort: {
                position: 1,
                created_at: 1
            }
        }
    ]
}

module.exports = {
    menuAggregate
}